@charset "utf-8";


// No fade in.
$intro-transition  : none;
$global-transition : none;

$code-background-color: #eee;
// $code-background-color-dark: #eee;


// Less padding on the sides.
$right-sidebar-width: 100px;
$right-sidebar-width-narrow: 100px;

$x-large: 1400px;


@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

// Keep things from getting too big on desktop.
body {
	@include breakpoint($large) {
		font-size: 90%;
	}
}

h2.archive__item-title {
	font-size: 2rem;
	margin: 2rem 0 0 0;
}
